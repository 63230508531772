import { SPEC_SYMBOLS } from '../../data/password-spec-symbols';

const checkLength = (s:string) => /^.{8,}$/.test(s);
const checkLower = (s:string) => /^(?=.*[a-z]).{8,}$/.test(s);
const checkUpper = (s:string) => /^(?=.*[A-Z]).{8,}$/.test(s);
const checkDigit = (s:string) => /^(?=.*[0-9]).{8,}$/.test(s);
const checkSpecial = (s:string) => SPEC_SYMBOLS.split('').some(char => s.includes(char));
const checkCyrillic = (s:string) => /^.*[а-яё]+.*$/i.test(s);

export const passwordStrongValidate = (password:string):string => {
  if (!checkLength(password)) return 'Минимум 8 символов';
  if (checkCyrillic(password)) return 'Не может содержать кириллицу';
  if (!checkLower(password)) return 'Должен содержать хотя бы один символ [a-z]';
  if (!checkUpper(password)) return 'Должен содержать хотя бы один символ [A-Z]';
  if (!checkSpecial(password)) return `Должен содержать хотя бы один из символов [${SPEC_SYMBOLS.split('').join(' ')}]`;
  if (!checkDigit(password)) return 'Должен содержать хотя бы одну цифру';

  return null;
};