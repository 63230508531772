export const createNavigationParams = <T>(nav:any[], values:T) => {
  const respParams:IReturnedParams<Record<string, any>> = {};
  const stateNav:INavigation<Record<string, any>, T> = nav?.pop();
  if (stateNav?.paramsToReturn) {
    stateNav.paramsToReturn.forEach(({ name, value }) => {
      respParams[name] = values[value] || '';
    });
    const stateNavToReturn = (nav?.pop() || {}) as INavigation<Record<string, any>, T>;
    stateNavToReturn.returnedParams = {
      ...(stateNavToReturn.returnedParams ? stateNavToReturn.returnedParams : {}),
      ...(stateNav.returnedParams ? stateNav.returnedParams : {}),
      ...respParams
    };
    nav.push(stateNavToReturn);
  }

  return {
    backUrl: stateNav?.backUrl,
    nav
  };
};