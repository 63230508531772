import { IDocumentSet } from '../entities/documents-set';
import { IValidationErrors } from './d';

export const documentsSetValidate = (values:IDocumentSet):IValidationErrors<IDocumentSet> => {
  const errors = {} as IValidationErrors<IDocumentSet>;

  if (!values.title) errors.title = 'Укажите название набора документа';
  if (!values.docs?.length && !values['docs[]']?.length) errors['docs[]'] = 'Не выбраны документы';

  return errors;
};