export enum UserRoles {
  'admin' = 'admin',
  'hr' = 'hr',
  'headhr' = 'headhr',
  'employer' = 'employer'
}

export const UserRolesRu:Record<keyof typeof UserRoles, string> = {
  admin: 'Администратор',
  hr: 'HR',
  headhr: 'Руководитель HR',
  employer: 'Кандидат'
};