import { flatObject } from '%/utils/flat-object';
import { ISortOrder } from '~/shared/components/sort-select/d';
import { trim } from '~/shared/tools/trim';

export const sortData = <T, >(
  { type, order, data }:{ type?:string, order?:ISortOrder, data:T[] }
):T[] => {
  const sorted = type ? data.sort((a, b) => {
      const aVal = flatObject(a)[type];
      const bVal = flatObject(b)[type];
      if (!aVal) return -1;
      if (!bVal) return 1;
      return trim(aVal).toLowerCase() >= trim(bVal).toLowerCase() ? 1 : -1;
    }) :
    data;

  type && order === 'desc' && sorted.reverse();

  return sorted;
};