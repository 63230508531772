import { useCallback } from 'react';

import { RecognizedTaskStatuses } from '%/data/recognized-task-statuses';
import { IError } from '%/entities/error';
import { IOffer } from '%/entities/offer';
import { useApi } from '~/core/api';
import { delay } from '~/shared/tools/delay';

export type WithRecognitionActionsProps = {
  startRecognition: (offerId:string) => Promise<IOffer|IError>
  pollTaskResult: (offerId:string) => Promise<IOffer>
  cancelRecognition: (offerId:string) => Promise<IOffer|IError>
}

export const withRecognizeActions = <T, >(WrappedComponent:React.FC<T>):React.FC<T & WithRecognitionActionsProps> => {
  const Component:React.FC<T & WithRecognitionActionsProps> = (props) => {
    const { fetchApi } = useApi();

    const startRecognition = useCallback(async (offerId:string):Promise<IOffer> =>
      await fetchApi(`/api/offers/${offerId}/recognition`, {
        method: 'post',
        notifyWhenFailed: true
      }), []);

    const cancelRecognition = useCallback(async (offerId:string):Promise<IOffer> =>
      await fetchApi(`/api/offers/${offerId}/recognition`, {
        method: 'delete'
      }), []);

    const taskPolling = useCallback(async (
      offerId:string,
      resolve:(val:IOffer) => void,
      reject:(val:any) => void
    ) => {
      const resp:IOffer|IError = await fetchApi(`/api/offers/${offerId}/recognition`);

      if ((resp as IOffer).recognitionTask?.status === RecognizedTaskStatuses.Pending ||
        (resp as IOffer).recognitionTask?.status === RecognizedTaskStatuses.InProgress
      ) {
        await delay(3000);
        taskPolling(offerId, resolve, reject);
        return;
      }

      if ((resp as IError).errorText) {
        reject(resp as IError);
      }
      resolve(resp as IOffer);
    }, []);

    const pollTaskResult = useCallback((offerId:string):Promise<IOffer> =>
      new Promise((resolve, reject) => {
        taskPolling(offerId, resolve, reject);
      }), []);

    return <WrappedComponent
      {...props}
      startRecognition={startRecognition}
      cancelRecognition={cancelRecognition}
      pollTaskResult={pollTaskResult}
    />;
  };

  return Component;
};