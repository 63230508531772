export const pluralize = (count:number, ...words:string[]):string[] => {
  let res = words[2] || words[0];
  if (count % 100 >= 5 && count % 100 <= 20) {
    res = words[2] || words[0];
  } else if (count % 10 === 1) {
    res = words[0];
  } else if (count % 10 === 0) {
    res = words[2] || words[0];
  } else if (count % 10 <= 4) {
    res = words[1] || words[0];
  }

  return [res, `${count} ${res}`];
};