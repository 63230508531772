import { IReport } from '../entities/report';
import { IValidationErrors } from './d';
import { cronValidate } from './tools/cron';

export const reportValidate = (values:IReport):IValidationErrors<IReport> => {
  const errors = {} as IValidationErrors<IReport>;
  if (!values.title) errors.title = 'Укажите название отчета';
  if (!values.name) errors.name = 'Укажите название отчета в БД ([a-z0-9] через дефис без пробелов)';
  if (!values.cron) errors.cron = 'Укажите периодичность отчета (cron формат)';
  if (values.cron && !cronValidate(values.cron)) errors.cron = 'Неверный формат';

  return errors;
};