import { IDocument } from '../entities/document';
import { IValidationErrors } from './d';

const docTitleRegexp = new RegExp(/^[a-z\d,-]{2,}$/);

export const createDocumentValidate = (values:IDocument):IValidationErrors<IDocument> => {
  const errors = {} as IValidationErrors<IDocument>;

  if (!values.title) errors.title = 'Укажите название документа';
  if (values.title && values.title.length < 2) errors.title = 'Минимум 2 символа';

  if (!values.docType) errors.docType = 'Не указан тип документа';
  if (values.docType && typeof values.docType !== 'string') {
    errors.docType = 'Значение должно быть строкой';
  } else if (values.docType && values.docType.length < 2) {
    errors.docType = 'Минимум 2 символа';
  } else if (values.docType && !docTitleRegexp.test(values.docType)) {
    errors.docType = 'Допустимые символы: 0-9, a-z, -';
  }

  if (values.errorTitle && typeof values.errorTitle !== 'string') {
    errors.errorTitle = 'Значение должно быть строкой';
  } else if (values.errorTitle && values.errorTitle.length < 2) {
    errors.errorTitle = 'Минимум 2 символа';
  }

  return errors;
};