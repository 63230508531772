import { isDefined } from './is-defined';

export const flatObject = (src:Record<string, unknown>):Record<string, unknown> => {
  const trg:Record<string, unknown> = {};

  Object.keys(src).forEach((key:string) => {
    if (Array.isArray(src[key])) {
      (src[key] as unknown[]).forEach((el:unknown, ind:number) => {
        if (isDefined(el) && typeof el !== 'object' && !Array.isArray(el) || !isDefined(el)) {
          trg[`${key}.${ind}`] = el;
        } else {
          const tmp = flatObject(el as Record<string, unknown>);

          Object.keys(tmp).forEach((tmpKey:string) => {
            trg[`${key}.${ind}.${tmpKey}`] = tmp[tmpKey];
          });
        }
      });
    } else if (isDefined(src[key]) && typeof src[key] === 'object') {
      const tmp = flatObject(src[key] as Record<string, unknown>);

      Object.keys(tmp).forEach((tmpKey:string) => {
        trg[`${key}.${tmpKey}`] = tmp[tmpKey];
      });
    } else {
      trg[key] = src[key];
    }
  });

  return trg;
};

export const deflatObject = (src:Record<string, unknown>|undefined|null):Record<string, unknown> => {
  if (!src) return src;

  const trg:Record<string, unknown> = {};
  const setValue = (key:string, value:unknown, obj:Record<string, unknown>|unknown[]) => {
    if (!key.includes('.')) {
      if (isNaN(parseInt(key))) {
        (obj as Record<string, unknown>)[key] = value;
      } else {
        (obj as unknown[]).push(value);
      }
    } else {
      const [, first, last] = key.match(/([^.]+).(.+)/);
      (obj as Record<string, unknown>)[first] = (obj as Record<string, unknown>)[first] || (isNaN(parseInt(last)) ? {} : []);
      setValue(last, value, (obj as Record<string, unknown>)[first] as Record<string, unknown>|unknown[]);
    }
  };

  Object.entries(src).forEach(([curKey, curValue]) => {
    setValue(curKey, curValue, trg);
  });

  return trg;
};