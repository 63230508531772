import { IAuthRequestBody } from '../entities/auth';
import { IValidationErrors } from './d';
import { phoneValidation } from './tools/phone';

export const authValidate = ({ phone, login, password }:IAuthRequestBody):IValidationErrors<IAuthRequestBody> => {
  const errors = {} as IValidationErrors<IAuthRequestBody>;

  if (!phone && !login) {
    errors.login = 'Введите номер телефона или email';
    errors.phone = phoneValidation(phone);
  }
  if (login && !password) {
    errors.password = 'Введите пароль';
  }
  if (phone && !login) {
    const phoneError = phoneValidation(phone);
    if (phoneError) errors.phone = phoneError;
  }

  return errors;
};