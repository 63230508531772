import { SPEC_SYMBOLS } from '%/data/password-spec-symbols';
import { passwordStrongValidate } from '%/validation/tools/password';

const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const digits = '0123456789';
const specSymbols = SPEC_SYMBOLS;
const chars = [lowerChars, specSymbols, digits, upperChars];

const MIN_LENGTH = 8;

export const generatePassword = (minLength = MIN_LENGTH):string => {
  let trg = '';

  const typedArr = new Uint8Array(minLength * 2);
  const typedChar = new Uint8Array(1);
  const random = [...window.crypto.getRandomValues(typedArr)];

  for (let i = 0;i < minLength; i++) {
    const charsTypeInd = [...window.crypto.getRandomValues(typedChar)][0];
    const set = chars[charsTypeInd % chars.length];
    trg += set[random[i] % set.length];
  }

  while (passwordStrongValidate(trg)) {
    const charsTypeInd = [...window.crypto.getRandomValues(typedChar)][0];
    const charsInd = [...window.crypto.getRandomValues(typedChar)][0];
    const set = chars[charsTypeInd % chars.length];
    trg += set[charsInd % set.length];
  }

  return trg;
};