export enum OfferTypes {
  'documents',
  'sb'
}

export enum OfferStatuses {
  'Created' = 'Created',
  'InProgress' = 'InProgress',
  'Completed' = 'Completed',
  'Closed' = 'Closed'
}

export const OfferStatusesRu = {
  'Created': 'Создан',
  'InProgress': 'В процессе',
  'Completed': 'Завершен',
  'Closed': 'Закрыт'
};