import { ICompany } from '../entities/company';
import { IValidationErrors } from './d';
import { emailValidation } from './tools/email';
import { phoneValidation } from './tools/phone';

export const companyValidation = (values:ICompany):IValidationErrors<ICompany> => {
  const errors = {} as IValidationErrors<ICompany>;

  if (!values.title) errors.title = 'Укажите название компании';
  const emailError = emailValidation(values.email);
  if (emailError) errors.email = emailError;

  const phoneError = phoneValidation(values.phone, false);
  if (phoneError) errors.phone = phoneError;

  return errors;
};