import { UserRoles } from '../data/user-roles';
import { IUser } from '../entities/user';
import { isAnyHr } from '../utils/user';
import { IValidationErrors } from './d';
import { passwordValidate } from './password';
import { emailValidation } from './tools/email';
import { phoneValidation } from './tools/phone';

export const userValidate = (values:IUser):IValidationErrors<IUser> => {
  let errors = {} as IValidationErrors<IUser>;

  const phoneError = phoneValidation(values.phone);
  const emailError = emailValidation(values.email);
  if (phoneError) errors.phone = phoneError;
  if (emailError) errors.email = emailError;
  if (!values.firstName) errors.firstName = 'Укажите имя';
  if (!values.lastName) errors.lastName = 'Укажите фамилию';
  if (!values.role) errors.role = 'Выберите роль пользователя';
  if (values.role && !(values.role in UserRoles)) errors.role = 'Выберите роль из списка';
  if (isAnyHr(values) && !values.companyId) errors.companyId = 'Укажите компанию';

  if (values.role !== UserRoles.employer && values.newPassword) {
    errors = {
      ...errors,
      ...passwordValidate({ newPassword: values.newPassword, retryPassword: values.retryPassword })
    };
  }

  return errors;
};