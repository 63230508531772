import { OfferStatuses } from '../data/offer';
import { UserRoles } from '../data/user-roles';
import { IDocument } from '../entities/document';
import { IOffer, IOfferData } from '../entities/offer';
import { IUser } from '../entities/user';
import { isAdmin, isAnyHr } from '../utils/user';
import { IValidationErrors } from './d';
import { phoneValidation } from './tools/phone';

export const offerValidate = (values:IOffer, documents:IDocument[]):IValidationErrors<IOffer> => {
  const errors = {} as IValidationErrors<IOffer>;

  if (!values.companyId) errors.companyId = 'Укажите компанию';
  if (values.template && !values.title) errors.title = 'Укажите заголовок';
  if (!values.template) {
    //@ts-ignore
    if (!values.data.firstName) errors.data = { ...errors.data, firstName: 'Укажите имя' };
    //@ts-ignore
    if (!values.data.lastName) errors.data = { ...errors.data, lastName: 'Укажите фамилию' };
    const phoneError = phoneValidation(values.data.phone);
    //@ts-ignore
    if (!values.template && phoneError) errors.data = { ...errors.data, phone: phoneError };
  }
  //@ts-ignore
  if (!values.data.documents?.length) errors.data = { ...errors.data, documents: 'Выберите хотя бы один документ' };
  if (values.data.documents?.some((doc:IDocument) => !documents.find(document => document.docType === doc.docType))) {
    //@ts-ignore
    errors.data = { ...errors.data, documents: 'Присутствует неподдерживаемый документ' };
  }

  return errors;
};

export const offerFillValidate = (values:IOfferData & { templateId?: string}, needAgreement = true):IValidationErrors<IOfferData> => {
  const errors = {} as IValidationErrors<IOfferData>;
  if (needAgreement && !values.personalAgreement) errors.personalAgreement = 'Без твоего согласия никак';
  if (!values.files?.length) errors.files = 'Загрузи документы';
  if (values.templateId) {
    if (!values.firstName) errors.firstName = 'Укажите имя';
    if (!values.lastName) errors.lastName = 'Укажите фамилию';
    const phoneError = phoneValidation(values.phone);
    if (phoneError) errors.phone = phoneError;
  }
  return errors;
};

export const offerStatusValidate = (
  values:Partial<IOffer>,
  fromStatus?: keyof typeof OfferStatuses,
  role?: keyof typeof UserRoles
):IValidationErrors<Partial<IOffer>> => {
  // TODO: may be need to add status change model
  const errors = {} as IValidationErrors<Partial<IOffer>>;

  if (!values.status || !OfferStatuses[values.status]) errors.status = 'Некорректный статус';

  if (
    // admin can cancel offer
    (!isAdmin({ role } as IUser) && values.status === OfferStatuses.Closed) ||
    (
    // hr can return to work offer
      !isAnyHr({ role } as IUser) &&
      ((fromStatus === OfferStatuses.Closed || fromStatus === OfferStatuses.Completed) &&
      values.status === OfferStatuses.InProgress)
    )
  ) errors.status = 'Некорректный статус';
  return errors;
};