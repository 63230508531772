import cronCheck from 'cron-validate';

export const VALID_FORMAT = '* * * * * * *';
export const VALID_FORMAT_EXPL = 'ss mm hh DoM M DoW YY';
export const FORMAT_EXPLAIN = [{
  name: 'Seconds',
  key: 'ss',
  chars: '0-59 , - * /'
}, {
  name: 'Minutes',
  key: 'mm',
  chars: '0-59 , - * /'
}, {
  name: 'Hours',
  key: 'hh',
  chars: '0-23 , - * /'
}, {
  name: 'Day of the Month',
  key: 'DoM',
  chars: '1-31 , - * ? / L W'
}, {
  name: 'Month',
  key: 'MM',
  chars: '1-12 JAN-DEC , - * /'
}, {
  name: 'Day of the Week',
  key: 'DoW',
  chars: ', - * ? / L #'
}, {
  name: 'Year',
  key: 'YY',
  chars: '1970-2099 , - * /'
}];

export const cronValidate = (value:string):boolean => cronCheck(value, {
  override: {
    useSeconds: true,
    useYears: true
  }
}).isValid();