import { IUserPassword } from '../entities/user';
import { IValidationErrors } from './d';
import { passwordStrongValidate } from './tools/password';

export const passwordValidate = ({ newPassword, retryPassword }:IUserPassword):IValidationErrors<IUserPassword> => {
  const errors = {} as IValidationErrors<IUserPassword>;
  if (!newPassword) errors.newPassword = 'Введите пароль';
  if (!retryPassword) errors.retryPassword = 'Введите повторно пароль';
  if (newPassword !== retryPassword && newPassword && retryPassword) errors.retryPassword = 'Пароли не совпадают';
  const checkStrong = newPassword && passwordStrongValidate(newPassword);
  if (checkStrong) errors.newPassword = checkStrong;

  return errors;
};